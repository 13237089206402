export function setWithExpiry(value, ttl) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  return item;
}

export function setURL(value, params, separator = "?") {
  return `${value}${params ? `${separator}${params.value}` : ""}`;
}
