export const MenuItems = [
  {
    value: "Men's Edit",
    link: `/collections/281619595343`,
    children: [],
  },
  {
    value: "Women's Edit",
    link: `/collections/281619529807`,
    children: [],
  },
  {
    value: "Shoes",
    link: `/collections/281619333199`,
    children: [],
  },
  {
    value: "Coats & Jackets",
    link: `/collections/281524961359`,
    children: [],
  },
  {
    value: "Accessories",
    link: `/collections/281468010575`,
    children: [],
  },
];
