"use client";
import Link from "next/link";
import Image from "next/image";
import styled from "@emotion/styled";
import { Fragment } from "react";
import { Divider, Grid, Typography } from "@mui/material";

import config from "config/env";
import { setURL } from "utils/outclicks";
import { SearchParamsHook } from "lib/utm";

const Logo = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 15px 12px !important;
  a {
    display: flex;
    align-items: center;
  }
`;

const FooterText = styled(Typography)`
  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  color: #121212;
  line-height: 2;
  text-align: center;
  letter-spacing: 0.04em;
  padding: 4px 0px;
  margin-bottom: 4px;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export default function Footer() {
  const queryParams = SearchParamsHook();
  return (
    <>
      <Divider sx={{ mt: 2 }} />
      <Grid container className="C012">
        <Logo item xs={12} className="C012-logo">
          <Link
            href={setURL(`${config.brandURL}`, queryParams)}
            className="C012-logo"
          >
            <Image
              src="https://demo-merca.myshopify.com/cdn/shop/files/merca-logo.png?v=1719213961&width=180"
              width={295}
              height={97}
              alt="logo"
              priority
              loading="eager"
              fetchPriority="high"
              unoptimized={true}
              className="C012-logo"
            />
          </Link>
        </Logo>
        <Logo item xs={12} className="C012-menu">
          <Link
            href={setURL(
              `${config.brandURL}/pages/terms-conditions`,
              queryParams,
            )}
            className="C012-menu-terms"
          >
            <FooterText className="C012-menu-terms">
              Terms and conditions
            </FooterText>
          </Link>
          <Link
            href={setURL(
              `${config.brandURL}/pages/privacy-policy`,
              queryParams,
            )}
            className="C012-menu-privacy"
          >
            <FooterText className="C012-menu-privacy">Privacy</FooterText>
          </Link>
          <FooterText className="C012-menu-year">
            © 2024, Merca Demo
          </FooterText>
        </Logo>
      </Grid>
    </>
  );
}
