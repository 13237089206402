"use client";

import { useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { useLocalStorage } from "usehooks-ts";

import { setWithExpiry } from "utils/outclicks";

export function SearchParamsHook() {
  const searchParams = useSearchParams();
  const queryString = searchParams.toString();

  const [queryParams, setQueryParams, removeValue] = useLocalStorage(
    "query-params",
    null,
  );

  useEffect(() => {
    const now = new Date();
    if (queryString) {
      setQueryParams(setWithExpiry(queryString, 30 * 24 * 60 * 60 * 1000));
    } else {
      if (queryParams && now.getTime() > queryParams.expiry) {
        removeValue();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return queryParams;
}
