import styled from "@emotion/styled";
import { Box, Fade, Menu, Typography } from "@mui/material";
import Link from "next/link";
import { Fragment } from "react";

const ChildHeader = styled(Typography)`
  letter-spacing: 0.3em;
  font-size: 0.8em;
  font-weight: 300;
  font-style: normal;
  color: #121212;
  transition: color 0.2s ease-in-out;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const SubChildText = styled(Typography)`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-transform: capitalize;
  color: #121212;
  background: #fff;
  line-height: 1.5;
  margin-top: 10px;
  &:hover {
    color: #6a6a6a;
  }
`;

const NoChildTypography = styled(Typography)`
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  background: #fff;
  line-height: 2;
  letter-spacing: 0.025em;
  display: flex;
  padding: 8px 15px;
  color: #2e2e2e;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 13px;
  text-transform: capitalize;
`;

export default function DesktopMenu({
  item,
  handleClose,
  open,
  labelId,
  anchorEl,
  parentIdx,
}) {
  return (
    <Menu
      id="fade-menu"
      anchorEl={anchorEl}
      MenuListProps={{
        "aria-labelledby": labelId,
      }}
      className={`C003-${parentIdx}-DesktopMenu`}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          width:
            item[0] && item[0].children && item[0].children.length
              ? "100%"
              : "fit-content",
          maxHeight: "400px",
          boxShadow: "none",
          padding: "15px 0px",
          overflowY: "auto",
          zIndex: "99999999999",
          borderRadius: "0 0 3px 3px",
          borderLeft: "1px solid #e1e3e4",
          borderRight: "1px solid #e1e3e4",
          borderBottom: "1px solid #e1e3e4",
        },
      }}
    >
      <Box
        onMouseLeave={handleClose}
        display="flex"
        flexDirection={
          item[0] && item[0].children && item[0].children ? "row" : "column"
        }
        justifyContent="center"
        alignItems="flex-start"
        padding={
          item[0] && item[0].children && item[0].children
            ? "20px 0px 0px"
            : "0px"
        }
        key={item.value}
        className={`C003-${parentIdx}-DesktopMenu`}
      >
        {item.map(({ children, value, link, utm }, idx) => {
          if (children && children.length) {
            return (
              <Fragment key={idx}>
                {!utm ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    key={value}
                    marginLeft="15px"
                    marginRight="30px"
                    className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                  >
                    <>
                      <Link
                        href={link}
                        className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                      >
                        <ChildHeader
                          className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                        >
                          {value}
                        </ChildHeader>
                      </Link>
                      {children.map((subChildItem, subChildIdx) => {
                        return (
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            key={subChildItem.value}
                            className={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                          >
                            <Link
                              href={subChildItem.link}
                              className={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                            >
                              <SubChildText
                                onClick={handleClose}
                                fontSize="15px"
                                className={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                              >
                                {subChildItem.value}
                              </SubChildText>
                            </Link>
                          </Box>
                        );
                      })}
                    </>
                  </Box>
                ) : (
                  <Link
                    href={link}
                    className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                  >
                    <ChildHeader
                      onClick={handleClose}
                      className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                    >
                      {value}
                    </ChildHeader>
                  </Link>
                )}
              </Fragment>
            );
          }
          return (
            <Box key={value} className={`C003-${parentIdx}-DesktopMenu-${idx}`}>
              <Link
                href={link}
                style={{ color: "#222" }}
                className={`C003-${parentIdx}-DesktopMenu-${idx}`}
              >
                <NoChildTypography
                  className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                >
                  {value}
                </NoChildTypography>
              </Link>
            </Box>
          );
        })}
      </Box>
    </Menu>
  );
}
