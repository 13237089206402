"use client";
import Link from "next/link";
import Image from "next/image";
import styled from "@emotion/styled";
import { Fragment, useState } from "react";
import { AppBar, Grid, Box, Typography, Button } from "@mui/material";

import config from "config/env";
import { setURL } from "utils/outclicks";
import { MenuItems } from "utils/drawerItem";
import { SearchParamsHook } from "lib/utm";

import MobileDrawer from "./drawer";
import DesktopMenu from "./DesktopMenu";
import { Menu } from "@mui/icons-material";

const Logo = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 18px 30px 6px;
  background-color: #fff;
  a {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 600px) {
    padding: 17px 10px !important;
    flex-direction: row;
  }
`;

const SubHeaderItems = styled(Typography)`
  color: #000;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  display: inline-block;
  padding: 7.5px 15px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  background-image: linear-gradient(#000 0 0);
  background-position: left -100% bottom 0; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition:
    background-size 0.5s,
    background-position 0s 0.5s;
  &:hover {
    background-position: left -100% bottom 0; /*OR bottom right*/
    background-size: 100% 2px;
  }
`;

export default function Header({ isMobile }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuSubItems, setMenuSubItems] = useState([]);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const queryParams = SearchParamsHook();
  const imageWidth = 142.5;
  const imageHeight = 46;
  return (
    <>
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ zIndex: "9", boxShadow: "0 0 1px rgba(0, 0, 0, 0.2)" }}
        className="C001"
      >
        <Grid container className="C001">
          <Logo item xs={12} className="C002">
            {isMobile ? (
              <Box
                position="absolute"
                top="25px"
                left="-5px"
                className="C002-menu"
              >
                <Button
                  sx={{ color: "#121212" }}
                  onClick={() => setOpen(true)}
                  className="C002-menu"
                >
                  <Menu fontSize="medium" />
                </Button>
              </Box>
            ) : null}
            <Link
              href={setURL(`${config.brandURL}`, queryParams)}
              className="C002-logo"
            >
              <Image
                src="https://demo-merca.myshopify.com/cdn/shop/files/merca-logo.png?v=1719213961&width=180"
                width={imageWidth}
                height={imageHeight}
                alt="logo"
                priority
                loading="eager"
                fetchPriority="high"
                unoptimized={true}
                className="C002-logo"
              />
            </Link>
            {!isMobile ? (
              <Box
                display="flex"
                flexDirection="row"
                mt={2}
                className="C002-desktop-menu"
              >
                {!isMobile &&
                  MenuItems.map((item, idx) => {
                    return !item.children.length ? (
                      <Link
                        href={item.link}
                        style={{ color: "#222" }}
                        key={item.value}
                        className={`C002-desktop-menu-${idx}`}
                      >
                        <Button
                          sx={{ padding: "0px" }}
                          className={`C002-desktop-menu-${idx}`}
                        >
                          <SubHeaderItems
                            className={`C002-desktop-menu-${idx}`}
                          >
                            {item.value}
                          </SubHeaderItems>
                        </Button>
                      </Link>
                    ) : (
                      <Fragment key={item.value}>
                        <Button
                          id={`fade-button-${idx}`}
                          onClick={(e) => {
                            handleClick(e);
                            setMenuSubItems(item.children);
                          }}
                          onMouseOver={(e) => {
                            handleClick(e);
                            setMenuSubItems(item.children);
                          }}
                          sx={{ padding: "0px" }}
                          className={`C002-desktop-menu-${idx}`}
                        >
                          <Link
                            href={item.link}
                            className={`C002-desktop-menu-${idx}`}
                          >
                            <SubHeaderItems
                              className={`C002-desktop-menu-${idx}`}
                            >
                              {item.value}
                            </SubHeaderItems>
                          </Link>
                        </Button>
                        <DesktopMenu
                          open={menuOpen}
                          anchorEl={anchorEl}
                          id={`fade-button-${idx}`}
                          handleClose={handleClose}
                          item={menuSubItems}
                          itemValue={item.value}
                          parentIdx={idx}
                        />
                      </Fragment>
                    );
                  })}
              </Box>
            ) : null}
          </Logo>
        </Grid>
      </AppBar>
      <MobileDrawer open={open} setOpen={setOpen} />
    </>
  );
}
